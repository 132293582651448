export const KEYS = "ttoCiOHMPjOkCjQBRE8OheHGWMi6hITr7iYmWDupyWXdKmP8DYTLp0xxrtbFLManRy2ETpfqHe8O60lOylGpcsv737SKM2IzXQDxam7dUMOkhqyKjypBnxaFW20qOoXxVQJBarMEQyxLlPsw5RXT3l0GLIasoSujCUDhAg83yXIBvMDZBEaBumdO3oXMtvvkhv3XdhQcL4RHZIHxdc3J0JJy8PbCImuwGsXFzKaXIEDC0wx7F7J3f1PoTHXtwA5L";

export const DOMAIN = "https://d4.by";

export const DOMAIN_API = "https://admin.d4.by/v1";

export const DICTIONARY = {
    goBack: "Выйти",
    logo: "D4.by",
    lastOrderOnTop: "Последние заказы отображаются вверху",
    oldVersion: "Старая версия",
    goToMain: "На главную d4.by",
    page404Text: "Вы достигли края вселенной.<br>Страница, которую вы ищете, не найдена.<br>Не волнуйтесь и вернитесь на предыдущую страницу.",
    page404Title: "Здесь ничего нет",
    page404Main: "Главная панель",
    pageStatistics: "Статистика по датам",
    pageStatisticsTires: "Статистика просмотров шин",
    pageStatisticsSpareParts: "Статистика просмотров З/Ч"
};

export const DISENABLE_BREADCRUMB = [
    'historyUsersIndex',
    'carDetail',
    'carCreate',
    'autoPartsDetail',
    'autoPartsCreate',
    'autoTiresDetail',
    'dashboardAdmin',
    'autoTiresCreate'
];

export const LEFT_MENU = [
    //7,8,22,2 - достойные люди
    //{title: 'Главная панель', component: 'dashboardAdmin', icon: 'bi-house-fill', permissions: []},
    {title: 'Добавить запчасть', component: 'autoPartsCreate', icon: 'bi-plus-square', permissions: []},
    {title: 'Мои запчасти', component: 'autoParts', icon: 'bi-basket2-fill', permissions: []},
    {title: 'Архив Запчастей', component: 'autoPartsArchive', icon: 'bi-archive', permissions: []},
    //{title: 'История пользователей', component: 'historyUsers', icon: 'bi-journal-text', permissions: []},
    //{title: 'Авто в разборе', component: 'carsAdmin', icon: 'bi-wrench', permissions: []},
    //{title: 'Добавить авто в разборе', component: 'carCreate', icon: 'bi-wrench', permissions: []},
    //{title: 'Мои шины', component: 'autoTires', icon: 'bi-vinyl', permissions: []},
    //{title: 'Архив Шин', component: 'autoTiresArchive', icon: 'bi-archive', permissions: []},
    //{title: 'Добавить шину', component: 'autoTiresCreate', icon: 'bi-plus-square', permissions: []},
    //{title: 'Статистика шин', component: 'tireStatistics', icon: 'bi-info-square-fill', permissions: []},
    //{title: 'Статистика З/Ч', component: 'sparePartsStatistics', icon: 'bi-info-square-fill', permissions: []}
];

export const STATUS = [
    "Активно",
    "Неактивно"
];

let today = new Date();
let year = today.getFullYear();
let yearsNum = parseInt(year) - 1980;
let years = [];

for(let i = 0; i <= yearsNum; i++) {
    var y = 1980 + i;
    years.push(y);
}

export const YEARS = years;

export const BODYS = [
    "седан",
    "хэтчбек",
    "универсал",
    "купе",
    "кабриолет",
    "минивеэн",
    "пикап",
    "фургон",
    "тягач",
    "лифтбек",
    "внедорожник"
];

export const TRANSMISSION = [
    "АКПП",
    "МКПП",
    "Робот",
    "Вариатор",
];

export const FUELS = [
    "дизель",
    "бензин",
    "гибрид",
    "электро"
];

export const TYPE_ENGINES_DISEL = [
    "CDi",
    "CDT",
    "CDTi",
    "CRD",
    "CRDi",
    "cTDi",
    "D",
    "D-4D",
    "D-CAT",
    "D5",
    "DCi",
    "DDiS",
    "Di",
    "DiD",
    "DT",
    "DTH",
    "DTi",
    "DTL",
    "HDi",
    "HPI",
    "HPT",
    "i-CTDi",
    "i-DTEC",
    "JTD",
    "SDi",
    "TD",
    "TDCi",
    "TDDi",
    "TDi",
    "TDi PD",
    "TDS",
    "TiD",
    "VCDi",
    "XDI",
];

export const TYPE_ENGINES_BENZ = [
    "FSI",
    "GDi",
    "HPi",
    "i",
    "IDE",
    "JTS",
    "Kompr",
    "MPI",
    "TCE",
    "TFSI",
    "THP",
    "Ti",
    "TSI",
    "VTEC",
    "VTI",
    "VVT-i",
    "карб",
    "моно",
];

export const TYPE_ENGINES_ALL = [
    "CDi",
    "CDT",
    "CDTi",
    "CRD",
    "CRDi",
    "cTDi",
    "D",
    "D-4D",
    "D-CAT",
    "D5",
    "DCi",
    "DDiS",
    "Di",
    "DiD",
    "DT",
    "DTH",
    "DTi",
    "DTL",
    "HDi",
    "HPI",
    "HPT",
    "i-CTDi",
    "i-DTEC",
    "JTD",
    "SDi",
    "TD",
    "TDCi",
    "TDDi",
    "TDi",
    "TDi PD",
    "TDS",
    "TiD",
    "VCDi",
    "XDI",
    "FSI",
    "GDi",
    "HPi",
    "i",
    "IDE",
    "JTS",
    "Kompr",
    "MPI",
    "TCE",
    "TFSI",
    "THP",
    "Ti",
    "TSI",
    "VTEC",
    "VTI",
    "VVT-i",
    "карб",
    "моно",
];

export const WHEEL_DIAMETER_R = [
    "R12",
    "R12C",
    "R13",
    "R13C",
    "R14",
    "R14C",
    "R15",
    "R15C",
    "R16",
    "R16.5",
    "R16C",
    "R17",
    "R17.5",
    "R17C",
    "R18",
    "R19",
    "R19.5",
    "R20",
    "R21",
    "R22",
    "R22.5",
    "R23",
    "R24",
    "R24.5",
    "R25"
];

export const WHEEL_WIDTH_J = [
    "5",
    "5.5",
    "6",
    "6.5",
    "7",
    "7.5",
    "8",
    "8.5",
    "9",
    "9.5",
    "10",
    "10.5",
    "11",
    "11.5",
    "12",
    "12.5"
];

export const NUMBER_OF_HOLES = [
    "3",
    "4",
    "5",
    "6",
    "10"
];

export const PCD = [
    "98",
    "100",
    "105",
    "108",
    "110",
    "112",
    "114.3",
    "115",
    "118",
    "120",
    "120.7",
    "127",
    "130",
    "139.7",
    "150",
    "160",
    "161",
    "165.1",
    "170",
    "180",
    "205"
];

export const DIAMETER = [
    "52.1",
    "54.1",
    "56.1",
    "56.6",
    "57.1",
    "58.1",
    "59.1",
    "59.6",
    "60.1",
    "63.4",
    "64.1",
    "65.1",
    "66.1",
    "66.6",
    "67.1",
    "70.1",
    "71.2",
    "72.6",
    "74.1",
    "78.1",
    "84.1",
    "85.1",
    "89.1",
    "93.1",
    "95.1",
    "98.1",
    "100.1",
    "108.1"
];

export const WIDTH = [
    "35",
    "80",
    "90",
    "100",
    "110",
    "115",
    "120",
    "125",
    "130",
    "135",
    "140",
    "145",
    "150",
    "155",
    "160",
    "165",
    "170",
    "175",
    "180",
    "185",
    "190",
    "195",
    "200",
    "205",
    "210",
    "215",
    "220",
    "225",
    "230",
    "235",
    "240",
    "245",
    "250",
    "255",
    "260",
    "265",
    "275",
    "280",
    "285",
    "295",
    "300",
    "305",
    "315",
    "320",
    "325",
    "335",
    "355",
    "385",
    "435",
    "445",
    "455"
];

export const HEIGHT = [
    "12",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "55",
    "60",
    "65",
    "70",
    "75",
    "80",
    "85",
    "90",
    "95",
    "100",
    "508"
];

export const R_SIZE = [
    "R12",
    "R12C",
    "R13",
    "R13C",
    "R14",
    "R14C",
    "R15",
    "R15C",
    "R16",
    "R16.5",
    "R16C",
    "R17",
    "R17.5",
    "R17C",
    "R18",
    "R19",
    "R19.5",
    "R20",
    "R21",
    "R22",
    "R22.5",
    "R23",
    "R24",
    "R24.5",
    "R25"
];

export const MARKA = [
    "Accelera",
    "Achilles",
    "Admiral",
    "Advance",
    "Aeolus",
    "Allosaurus",
    "Altenzo",
    "Alventi",
    "Amberway",
    "Amtel",
    "Anchee",
    "Annaite",
    "Antares",
    "Aoteli",
    "Aplus",
    "Apollo",
    "Aptany",
    "Arctic Claw",
    "Arivo",
    "Arizonian",
    "Arrowspeed",
    "Arroyo",
    "Artum",
    "Aspen",
    "Atlas",
    "Atturo",
    "Aurora",
    "Austone",
    "Autogreen",
    "Autogrip",
    "Autoguard",
    "Avon",
    "Bandvulc",
    "Barkley",
    "Barum",
    "BCT",
    "Benchmark",
    "Berlin",
    "Berliner",
    "BFGoodrich",
    "Blackhawk",
    "Blacklion",
    "Blackstone",
    "Blue Streak",
    "Bob Jane",
    "BOTO",
    "Bridgestone",
    "Briway",
    "Caldera Tires",
    "Camac",
    "Capitol",
    "Carbon Series",
    "Ceat",
    "Cencotire",
    "Centara",
    "Champiro",
    "ChaoYang",
    "Charmhoo",
    "Chengshan",
    "Chuanshi",
    "Churchill",
    "Clear",
    "Coalstar",
    "Collins",
    "Comforcer",
    "Compasal",
    "Constancy",
    "Continental",
    "Contyre",
    "Cooper",
    "Copro",
    "Cordiant",
    "Cornell",
    "Cosmo",
    "Cratos",
    "Dailyway",
    "Davanti",
    "Dayton",
    "Dean",
    "Debica",
    "Deestone",
    "Definity",
    "Delinte",
    "Delmax",
    "Delta",
    "Deruibo",
    "Dextero",
    "Diamondback",
    "DMACK",
    "Double Coin",
    "Double Happiness",
    "DoubleStar",
    "Douglas",
    "Dunlop",
    "Duramold",
    "Duraturn",
    "Duro",
    "Durun",
    "Dynamo",
    "Ecovision",
    "Effiplus",
    "Eldorado",
    "Equipe",
    "ESA-Tecar",
    "Eternity",
    "Eurotec",
    "Event",
    "Evergreen",
    "Excelon",
    "Falken",
    "Farroad",
    "Fate",
    "Federal",
    "Fighter",
    "Firemax",
    "Firenza",
    "Firestone",
    "First Stop",
    "Forceum",
    "Formula",
    "Fortio",
    "Fortuna",
    "FronWay",
    "Fulda",
    "FullRun",
    "Fullway",
    "Futura",
    "Fuzion",
    "Gauth Pneus",
    "Gemstone",
    "Gencotire",
    "General",
    "General Grabber",
    "Gerutti",
    "Gislaved",
    "Giti",
    "Glob-Gum",
    "Goalstar",
    "Goform",
    "Gold-Gum",
    "GoldenCrown",
    "Goldline",
    "GoldWay",
    "Goodride",
    "GoodTrip",
    "Goodyear",
    "goPro",
    "Govino",
    "Gremax",
    "Grenlander",
    "GripMax",
    "GT Radial",
    "Habilead",
    "Haida",
    "Hankook",
    "Headway",
    "Heidenau",
    "Hemisphere",
    "Hercules",
    "Hero",
    "Hifly",
    "Hilo",
    "Horizon",
    "Ilink",
    "Imperial",
    "Infinity",
    "Insa Turbo",
    "Interstate",
    "Intertrac",
    "Invovic",
    "Iota",
    "Ironman",
    "Jetzon",
    "Jinyu",
    "JK Tyre",
    "Joyroad",
    "Kapsen",
    "Kelly",
    "Kenda",
    "Keter",
    "Kinforest",
    "King-Meiler",
    "Kingpin",
    "Kingrun",
    "Kingstar",
    "Kleber",
    "Kormoran",
    "Kpatos",
    "Kratos",
    "Kumho",
    "LandBolder",
    "Landsail",
    "Lanvigator",
    "Lanwoo",
    "Lassa",
    "Laufenn",
    "Leao",
    "Lexani",
    "LingLong",
    "LionHart",
    "Luckyland",
    "Luistone",
    "Luxxan",
    "Mabor",
    "Malatesta",
    "Marangoni",
    "Marix",
    "Marshal",
    "Massimo",
    "Master",
    "Mastercraft",
    "Mastersteel",
    "Matador",
    "Maxmiller",
    "Maxtrek",
    "Maxxis",
    "Mayrun",
    "Mazzini",
    "Medalion",
    "Membat",
    "Mentor",
    "Meteor",
    "Metzeler",
    "Michelin",
    "Mickey Thompson",
    "Midas",
    "Milestar",
    "Milestone",
    "Minerva",
    "Minnell",
    "Mirage",
    "Mohawk",
    "MotoMaster",
    "Motrio",
    "Multi-Mile",
    "Multirac",
    "Nankang",
    "Negotiator",
    "Neolin",
    "Nereus",
    "Nexen",
    "Next Tread",
    "Nika",
    "Nitto",
    "Nokian",
    "Norauto",
    "Nordexx",
    "Nordman",
    "Novex",
    "Ohtsu",
    "Onyx",
    "Opals",
    "Orium",
    "Otani",
    "Outside",
    "Ovation",
    "Pace",
    "Pantera",
    "Permanent",
    "Petlas",
    "Pirelli",
    "Platin",
    "Pneumant",
    "Points",
    "Powertrac",
    "Premada",
    "Premiorri",
    "Presa",
    "Prestivo",
    "Primewell",
    "Prinx",
    "Profil",
    "Provato",
    "Radar",
    "Rapid",
    "Regal",
    "Rigdon",
    "Riken",
    "Road Hugger",
    "Roadclaw",
    "Roadcruza",
    "Roadking",
    "Roadmarch",
    "Roadshine",
    "Roadstone",
    "RoadX",
    "Rockblade",
    "Rockstone",
    "Rosava",
    "Rotalla",
    "Rotex",
    "Routeway",
    "Rovelo",
    "Royal Black",
    "Runway",
    "Rydanz",
    "Saferich",
    "Sagitar",
    "Sailun",
    "Sailwin",
    "Sava",
    "Sebring",
    "Security",
    "Securo",
    "Semperit",
    "Sentury",
    "Silverstone",
    "Sonar",
    "Sonny",
    "Spider",
    "Sportiva",
    "Stampede",
    "Star Performer",
    "Starfire",
    "Starmaxx",
    "Strial",
    "Sumitomo",
    "Sunew",
    "Sunfull",
    "Sunitrac",
    "Sunny",
    "Suntek",
    "Sunwide",
    "Supercat",
    "Superia",
    "Syron",
    "Taganca",
    "Taurus",
    "TeamStar",
    "Tecar",
    "Three-a",
    "Thunderer",
    "Tigar",
    "Toledo",
    "Tomket",
    "Torque",
    "Toyo",
    "Tracmax",
    "Travelstar",
    "Trayal",
    "Trazano",
    "Tri Ace",
    "Triangle",
    "Tristar",
    "Tunga",
    "Tyfoon",
    "TyRex",
    "Uniroyal",
    "Vee Rubber",
    "Vercelli",
    "Viatti",
    "Viking",
    "Vitora",
    "Voltyre",
    "Vredestein",
    "Wanli",
    "WestLake",
    "Winda",
    "Windforce",
    "Winrun",
    "Winter Tact",
    "Xcent",
    "Yatone",
    "Yeada",
    "Yokohama",
    "Zeetex",
    "Zenna",
    "Zeta",
    "Zetum",
    "Zmax",
    "Белшина",
    "Кама",
    "Кама-Евро",
    "КШЗ",
    "Медведь",
    "Метелица",
    "Омскшина",
    "Росшина"
];

export const CONDITION = [
    "новые",
    "б/у",
    "наварные"
];

export const SEASON = [
    "зимние",
    "летние",
    "всесезонные"
];